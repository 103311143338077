import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'leave-class-modal',
  props: {
    classId: {
      type: String
    },
    className: {
      type: String
    }
  },
  data() {
    return {
      lmsScheduleOptions: [],
      lmsScheduleId: '',
      reason: '',
      leaveClassType: ['Izin Satu Hari', 'Izin Setengah Hari'],
      leaveClassTypeSelected: '',
      isContentVisible: false
    }
  },

  watch: {
    classId(lmsClassId) {
      this.lmsScheduleOptions = []
      if (lmsClassId !== undefined) {
        this.getScheduleOnward({ classId: lmsClassId }).then(
          (response) => {
            response.map((res) => {
              this.lmsScheduleOptions.push(res)
            })
            this.lmsScheduleId = this.lmsScheduleOptions[0].id
            this.leaveClassTypeSelected = 'Izin Satu Hari'
          },
          () => {}
        )
      } else {
        this.lmsScheduleId = ''
        this.reason = ''
      }
    }
  },

  computed: {
    leaveClassTypeOrigin() {
      return this.leaveClassTypeSelected === 'Izin Satu Hari' ? 'LEAVE_FULL' : 'LEAVE_HALF'
    }
  },

  methods: {
    ...mapActions('student', ['getScheduleOnward']),
    close() {
      this.$emit('close')
    },
    save() {
      if (this.reason === '') {
        showVueToast('Alasan tidak boleh kosong!', 'error', 3000)
        return
      }

      this.$emit('save', this.lmsScheduleId, this.reason, this.leaveClassTypeOrigin)
    }
  }
}
