import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
import CloseWithCircle from '@/components/icons/CloseWithCircle'
import Upload from '@/components/icons/Upload'
import LoadingDot from '@/components/illustration/LoadingDot'
export default {
  components: {
    CloseWithCircle,
    Upload,
    LoadingDot
  },
  props: {
    classId: String,
    isNonWebinar: {
      type: Boolean,
      default: false
    },
    tugasActivityItemId: {
      type: Number
    },
    toggleSubmissionFailedModal: Function
  },
  data: () => ({
    seletedFile: {},
    error: null,
    isReadyToSend: false,
    isLoading: false
  }),
  created() {
    this.seletedFile = null
  },
  methods: {
    ...mapActions('prakerja', ['uploadTugas', 'uploadTugasNonWebinar']),
    close(event) {
      this.$emit('close')
    },
    onChange() {
      this.seletedFile = this.$refs.file.files
      if (this.seletedFile.length > 1) {
        this.error = 'Anda hanya bisa memilih 1 dokumen saja!'
        this.isReadyToSend = false
        return
      } else if (this.seletedFile.length === 1) {
        const unwantedExtensions = ['.exe', '.dll', '.apk']
        for (const ext of unwantedExtensions) {
          if (this.seletedFile[0].name.endsWith(ext)) {
            this.error = `File dengan extension ${ext} tidak diperbolehkan`
            this.isReadyToSend = false
            return
          }
        }
      }
      this.isReadyToSend = true
    },
    closeAndRefetch() {
      this.$emit('closeAndRefetch')
    },
    submit() {
      if (this.isLoading) return
      this.isLoading = true
      const filePayload = new FormData()
      filePayload.append('file', this.seletedFile[0])
      filePayload.append('filename', this.seletedFile[0].name)
      let action = null
      let data = {}
      if (this.isNonWebinar) {
        action = this.uploadTugasNonWebinar
        data = {
          studentLmsClassId: this.classId,
          activityItemId: this.tugasActivityItemId,
          payloads: filePayload
        }
      } else {
        action = this.uploadTugas
        data = {
          classId: this.classId,
          payloads: filePayload
        }
      }
      action(data)
        .then((res) => {
          showVueToast('Tugas berhasil dikumpulkan!', 'success', 3000)
          this.isLoading = false
          this.closeAndRefetch()
        })
        .catch((e) => {
          const errorMessage = e.response?.data?.errors?.error || ''
          this.toggleSubmissionFailedModal(true, errorMessage)
          // showVueToast('Tugas gagal dikumpulkan, silakan coba lagi!', 'error', 3000)
          this.isLoading = false
        })
    },
    remove(i) {
      this.seletedFile = {}
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('uploading')) {
        event.currentTarget.classList.add('uploading')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('uploading')
    },
    drop(event) {
      this.error = null
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('uploading')
    }
  }
}
